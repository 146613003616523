export const environment = {
  production: false,
  acc: true,
  test: false,
  dev: false,
  local: false,
  url: {
    api: 'api.acc.rallygo.eu',
    images: 'images.acc.rallygo.eu',
    events: 'https://events.acc.rallygo.eu',
    live: 'https://live.acc.rallygo.eu',
    deposit: 'https://deposit.acc.rallygo.eu'
  }
};
